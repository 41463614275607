import React from 'react'
import Carousel from './Carousel'
import "../styles/Home.css"

const Home = () => {
  return (
    <main className="home-container">


<Carousel/>


</main>
  )
}

export default Home