import React from 'react'
import '../styles/Novedades.css'

const Novedades = () => {
  return (
    <main className="novedades-cont">
        <h1>

        Novedades

        </h1>
        
        
        </main>
  )
}

export default Novedades